import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import Signup from "../components/Signup";
import {
  checkOperatorService,
  activateLPPolicyService,
  getMetaDataAndFlowService,
  sendLPOtpService,
  checkUtmService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";
import { tagManagerParamsEvents } from "../utils/GoogleTagManager";
import EventsName from "../constants/EventsName";
import { getErrorData, isPlanMatch } from "../utils/CommonFunctions";
import AppMessage from "../constants/AppMessage";

type Props = {
  token: string;
};

type UiStateType = {
  logo: string;
  benefits: string;
  bgImgDesktop: string;
  bgImgMobile: string;
  artWork: string;
};
type PriceStateType = {
  priceType: string;
  price: string;
};

let adNetwork: string = "";
let source: string = "";
let initialHEMsisdn: string = "";
let planSubPackage: string = "";
let userSubPackage: string | null = null;

const SignupScreen = (props: Props) => {
  const { planName = "", utmSource = "" } = useParams();

  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [msisdn, setMsisdn] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // subscribeLoading for only Login loading button and functionality
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const [mainLoading, setMainLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0); // Counter state to manage timer
  const [planId, setPlanId] = useState<number>(-1);
  const [partnerId, setPartnerId] = useState<number>(-1);

  const [priceState, setPriceState] = useState<PriceStateType>({
    priceType: "",
    price: "",
  });
  const [uiState, setUIState] = useState<UiStateType>({
    logo: "",
    benefits: "",
    bgImgDesktop: "",
    bgImgMobile: "",
    artWork: "",
  });

  useEffect(() => {
    let userId = localStorage.getItem("user_Id");
    if (userId) {
      navigate("/home");
    } else {
      if (props.token) {
        getFlow();
        if (utmSource) checkUtm();
      }
    }
  }, [props.token]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input
    }
  }, []);

  // useEffect hook to manage the countdown when the timer starts
  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup on component unmount or counter reset
    }
  }, [counter]);

  //   Get Flow
  const getFlow = async () => {
    try {
      let data = {
        pp_config_title: planName,
        utm_source: utmSource,
      };
      let response = await getMetaDataAndFlowService(data);
      setPlanId(response.result.plan_id);
      setPartnerId(response.result.partner_id);

      setPriceState((prevState: any) => ({
        ...prevState,
        price: response.result.price,
        priceType: response.result.price_type,
      }));
      setUIState((prevState: any) => ({
        ...prevState,
        logo: response.result.logo,
        benefits: response.result.benefits,
        bgImgDesktop: response.result.bg_img_desktop,
        bgImgMobile: response.result.bg_img_mobile,
        artWork: response.result.art_work,
      }));
      source = response.result.source;

      planSubPackage = response.result.plan_sub_package;

      userSubPackage = response.result.sub_package;

      if (response.result.msisdn) {
        initialHEMsisdn = response.result.msisdn.slice(-10);

        setMsisdn(initialHEMsisdn);

        if (response.result.fast_flow && response.result.cdff) {
          if (!isPlanMatch(planSubPackage, userSubPackage)) {
            return;
          }
          let data_ = {
            msisdn: initialHEMsisdn,
            otp: response.result.cdff,
            plan_id: response.result.plan_id,
            utm_source: utmSource,
            source: response.result.source,
          };
          activeLPPolicy(data_);
        }
      }
      setMainLoading(false);
    } catch (error) {
      setMainLoading(false);
      navigate("/home");
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   msisdn

  const onMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const onPressSendOTPButton = async () => {
    setErrorMsg("");
    if (msisdn.length === 10) {
      if (initialHEMsisdn === msisdn && userSubPackage) {
        if (isPlanMatch(planSubPackage, userSubPackage)) {
          sendOTP(msisdn, partnerId);
        } else {
          if (planSubPackage.toLowerCase() === "prepaid") {
            setErrorMsg(AppMessage.onlyForPrepaid);
          } else {
            setErrorMsg(AppMessage.onlyForPostpaid);
          }
        }
      } else {
        checkOperator();
      }
    } else {
      setErrorMsg(AppMessage.msisdnLength);
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });
      if (response.result.isOther === false) {
        let subPackage: string | null = response.result.package;

        if (!isPlanMatch(planSubPackage, subPackage)) {
          console.log(planSubPackage);
          if (planSubPackage.toLowerCase() === "prepaid") {
            setErrorMsg(AppMessage.onlyForPrepaid);
          } else {
            setErrorMsg(AppMessage.onlyForPostpaid);
          }
        } else {
          sendOTP(msisdn, partnerId);
        }
      } else {
        setIsLoading(false);
        setErrorMsg(AppMessage.otherOperator);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  const sendOTP = async (num: string, _partnerId: number) => {
    try {
      setIsLoading(true);
      let _data = {
        msisdn: num,
        partner_id: _partnerId,
      };
      let response = await sendLPOtpService(_data);
      if (msisdn === initialHEMsisdn) {
        let arr = response.result.split("");
        setOtp(arr);
      }
      setShowOtp(true);
      setErrorMsg("");
      setCounter(30);
      setIsLoading(false);
    } catch (error) {
      setShowOtp(false);
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   Otp
  const pinInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      onCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const onPINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        onCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const onOTPSubmit = () => {
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      let data_ = {
        msisdn: msisdn,
        otp: _otp,
        plan_id: planId,
        utm_source: utmSource,
        source: source,
      };
      activeLPPolicy(data_);
    } else {
      setErrorMsg(AppMessage.invalidOtp);
    }
  };

  const activeLPPolicy = async (data: any) => {
    try {
      let eventParams = {
        planName: planName.toLowerCase(),
        utm_source: utmSource,
      };
      setSubscribeLoading(true);
      let response = await activateLPPolicyService(data);
      if (
        adNetwork.toLowerCase() === "tiktok" ||
        adNetwork.toLowerCase() === "tik tok"
      ) {
        eventParams.utm_source = `tiktok-${source.toLowerCase()}`;
        tagManagerParamsEvents(EventsName.subscribePolicy, eventParams);
      } else {
        tagManagerParamsEvents(EventsName.subscribePolicy, eventParams);
      }

      setSubscribeLoading(false);
      navigate("/home");
    } catch (error: any) {
      setSubscribeLoading(false);

      if (getErrorData(error) === "policy already purchased") {
        setErrorMsg(AppMessage.alreadyPurchased);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }

      console.log("Error while checking user: ", error);
    }
  };

  const backButton = () => {
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    setShowOtp(false);
  };

  // Function to resend click
  const onResendClick = async () => {
    try {
      setOtp(["", "", "", ""]);
      setIsLoading(true);

      let _data = {
        msisdn: msisdn,
        partner_id: partnerId,
      };
      let response = await sendLPOtpService(_data);

      setIsLoading(false);
      setErrorMsg("");
      setCounter(30);
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };
  //Utm
  const checkUtm = async () => {
    try {
      let response = await checkUtmService();
      response.result.map((e: any) => {
        if (utmSource === e.sf_utm) {
          adNetwork = e.ad_network;
        }
      });
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  return (
    <Signup
      msisdn={msisdn}
      errorMsg={errorMsg}
      onMsisdnChange={onMsisdnChange}
      showOtp={showOtp}
      onResendClick={onResendClick}
      counter={counter}
      otp={otp}
      inputRefs={inputRefs}
      pinInputChange={pinInputChange}
      onOTPSubmit={onOTPSubmit}
      onPINInput={onPINInput}
      backButton={backButton}
      onPressSendOTPButton={onPressSendOTPButton}
      isLoading={isLoading}
      mainLoading={mainLoading}
      // FrontEnd Images
      uiState={uiState}
      priceState={priceState}
      subscribeLoading={subscribeLoading}
    />
  );
};

export default SignupScreen;
