import React, { useEffect, useRef, useState } from "react";
import {
  checkOperatorService,
  generateWaadaOTPService,
  activateMobileInsuranceService,
  getHeaderEnrichmentService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import LandingWada from "../components/LandingWada";
import { getErrorData } from "../utils/CommonFunctions";
import AppMessage from "../constants/AppMessage";

type Props = {
  token: string;
};

let initialHEMsisdn: String = "";
const LandingWaadaScreen = (props: Props) => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [msisdn, setMsisdn] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  // isLoading for all loading button and functionality
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // loginLoading for only Login loading button and functionality
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);

  const [inputValue, setInputValue] = useState({
    name: "",
    cnic: "",
    phone_make: "",
    phone_model: "",
    imei_1: "",
    imei_2: "",
  });

  const [inputError, setInputError] = useState({
    name: false,
    cnic: false,
    phone_make: false,
    phone_model: false,
    imei_1: false,
    imei_2: false,
  });

  const [errorText, setErrorText] = useState({
    name: "",
    cnic: "",
    phone_make: "",
    phone_model: "",
    imei_1: "",
    imei_2: "",
  });
  const [counter, setCounter] = useState(0); // Counter state to manage timer

  const [isUserProfileModalVisible, setIsUserProfileModalVisible] =
    useState(false);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input
    }
  }, []);

  // useEffect hook to manage the countdown when the timer starts
  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup on component unmount or counter reset
    }
  }, [counter]);

  useEffect(() => {
    let userId = localStorage.getItem("user_Id");
    if (userId) {
      navigate("/home");
    } else {
      if (props.token) {
        getHeaderEnrichment();
      }
    }
  }, [props.token]);

  const closeUserProfileModal = () => {
    if (msisdn.length === 10) {
      setErrorMsg("");
      checkOperator();
    } else {
      setErrorMsg(AppMessage.msisdnLength);
    }
  };

  // Function to resend click
  const onResendClick = async () => {
    try {
      setIsLoading(true);
      let response = await generateWaadaOTPService({ msisdn });

      setIsLoading(false);
      setErrorMsg("");
      setCounter(30);
    } catch (error) {
      setIsLoading(false);

      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   He Flow
  const getHeaderEnrichment = async () => {
    try {
      let response = await getHeaderEnrichmentService();
      if (response.result) {
        let _num = response.result.slice(-10);
        setMsisdn(_num);
        initialHEMsisdn = _num;
      }
    } catch (error) {
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  // on input changes
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const trimmedValue = name === "name" ? value.trim() : value;

    setInputValue((prevState) => ({ ...prevState, [name]: trimmedValue }));

    // Reset errors when the user starts typing
    setInputError((prevState) => ({ ...prevState, [name]: false }));
    setErrorText((prevState) => ({ ...prevState, [name]: "" }));
  };

  // Example of validation for CNIC and Name
  //   const validateInput = (): boolean => {
  //     let valid = true;
  //     const errors: any = {};
  //     const errorMessages: any = {};

  //     if (!inputValue.name.match(/^[A-Za-z\s]+$/)) {
  //       errors.name = true;
  //       errorMessages.name = "Name should contain only letters and spaces";
  //       valid = false;
  //     }

  //     if (
  //       inputValue.cnic.length !== 15 ||
  //       !/^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(inputValue.cnic)
  //     ) {
  //       errors.cnic = true;
  //       errorMessages.cnic = "CNIC should be in the format xxxxx-xxxxxxx-x";
  //       valid = false;
  //     }

  //     setInputError((prevState) => ({ ...prevState, ...errors }));
  //     setErrorText((prevState) => ({ ...prevState, ...errorMessages }));
  //     return valid;
  //   };

  const validateInput = (): boolean => {
    let valid = true;
    const errors: any = {};
    const errorMessages: any = {};

    // Validate name: should contain only letters and spaces, and cannot be empty
    if (!inputValue.name.trim() || !inputValue.name.match(/^[A-Za-z\s]+$/)) {
      errors.name = true;
      errorMessages.name =
        "Name should contain only letters and cannot be empty";
      valid = false;
    }

    // Validate CNIC: must be 15 characters long and match the format xxxxx-xxxxxxx-x
    if (
      !inputValue.cnic ||
      inputValue.cnic.length !== 15 ||
      !/^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(inputValue.cnic)
    ) {
      errors.cnic = true;
      errorMessages.cnic = "CNIC should be in the format xxxxx-xxxxxxx-x";
      valid = false;
    }

    // Validate phone make: cannot be empty
    if (!inputValue.phone_make.trim()) {
      errors.phone_make = true;
      errorMessages.phone_make = "Phone make cannot be empty";
      valid = false;
    }

    // Validate phone model: cannot be empty
    if (!inputValue.phone_model.trim()) {
      errors.phone_model = true;
      errorMessages.phone_model = "Phone model cannot be empty";
      valid = false;
    }

    // Validate IMEI 1: must be 15 characters long
    if (!inputValue.imei_1 || inputValue.imei_1.length !== 15) {
      errors.imei_1 = true;
      errorMessages.imei_1 = "IMEI 1 must be 15 characters long";
      valid = false;
    }

    // Validate IMEI 2: optional but if provided, must be 15 characters long
    if (inputValue.imei_2 && inputValue.imei_2.length !== 15) {
      errors.imei_2 = true;
      errorMessages.imei_2 = "IMEI 2 must be 15 characters long if provided";
      valid = false;
    }

    // Validate IMEI 1: cannot be empty
    // if (!inputValue.imei_1.trim()) {
    //   errors.imei_1 = true;
    //   errorMessages.imei_1 = "IMEI 1 cannot be empty";
    //   valid = false;
    // }

    // IMEI 2 is optional, so no validation needed

    setInputError((prevState) => ({ ...prevState, ...errors }));
    setErrorText((prevState) => ({ ...prevState, ...errorMessages }));

    return valid;
  };

  const OnPressSendOTPButton = () => {
    if (validateInput()) {
      sendOTP();
    }
  };

  const onMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });

      if (
        response.result.isOther === false &&
        response.result.package.toLowerCase() === "pre"
      ) {
        setIsUserProfileModalVisible(!isUserProfileModalVisible);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (response.result.isOther) {
          setErrorMsg(AppMessage.otherOperator);
        } else if (response.result.package.toLowerCase() === "post") {
          setErrorMsg(AppMessage.onlyForPrepaid);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  const sendOTP = async () => {
    try {
      setIsLoading(true);
      let response = await generateWaadaOTPService({ msisdn });
      if (msisdn === initialHEMsisdn) {
        setOtp([...response.result]);
      }

      setIsUserProfileModalVisible(false);
      setShowOtp(true);
      tagManagerEvents("Handset_Get_Otp", utmSource);
      setErrorMsg("");
      setCounter(30);
      setIsLoading(false);
    } catch (error) {
      setShowOtp(false);
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   Otp
  const onPINInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      onCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const onPINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        onCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const onOTPSubmit = () => {
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      loginUser(_otp);
    } else {
      setErrorMsg(AppMessage.invalidOtp);
    }
  };

  const loginUser = async (_otp: string) => {
    try {
      setLoginLoading(true);
      let data = {
        msisdn: msisdn,
        otp: _otp,
        name: inputValue.name,
        cnic: parseInt(inputValue.cnic.replaceAll("-", ""), 10),
        handSetInfo: {
          phone_make: inputValue.phone_make,
          phone_model: inputValue.phone_model,
          imei_1: inputValue.imei_1,
          imei_2: inputValue.imei_2,
        },
      };
      let response = await activateMobileInsuranceService(data);

      setLoginLoading(false);
      tagManagerEvents("Handset_Purchased", utmSource);

      navigate("/home");
    } catch (error: any) {
      setLoginLoading(false);

      if (getErrorData(error) === "policy already purchased") {
        setErrorMsg(AppMessage.alreadyPurchased);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
      console.log("Error while checking user: ", error);
    }
  };

  const backButton = () => {
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    setShowOtp(false);
  };

  return (
    <LandingWada
      msisdn={msisdn}
      errorMsg={errorMsg}
      isLoading={isLoading}
      onMsisdnChange={onMsisdnChange}
      OnPressSendOTPButton={OnPressSendOTPButton}
      showOtp={showOtp}
      inputValue={inputValue}
      inputError={inputError}
      errorText={errorText}
      onInputChange={onInputChange}
      onResendClick={onResendClick}
      closeUserProfileModal={closeUserProfileModal}
      isUserProfileModalVisible={isUserProfileModalVisible}
      counter={counter}
      otp={otp}
      inputRefs={inputRefs}
      onPINInputChange={onPINInputChange}
      onOTPSubmit={onOTPSubmit}
      onPINInput={onPINInput}
      backButton={backButton}
      loginLoading={loginLoading}
    />
  );
};

export default LandingWaadaScreen;
