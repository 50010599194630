import { AxiosError } from "axios";

export const getErrorData = (e: unknown) => {
  try {
    if (e instanceof AxiosError && e?.response?.data?.result) {
      return e?.response?.data?.result?.toLowerCase();
    }
  } catch (e) {}
};

export const formatCnic = (input: string) => {
  try {
    let digits = input.toString().replace(/\D/g, "");
    // if (input.length > 0 && !/^[1-7]/.test(input[0])) {
    //   return '';
    // }
    if (digits.length > 5) {
      digits = digits.slice(0, 5) + "-" + digits.slice(5);
    }
    if (digits.length > 13) {
      digits = digits.slice(0, 13) + "-" + digits.slice(13, 14);
    }

    return digits;
  } catch (e) {
    return "";
  }
};

export const isPlanMatch = (
  planSubPackage: string,
  subPackage: string | null
): boolean => {
  if (planSubPackage && subPackage) {
    if (planSubPackage.toLowerCase().includes(subPackage.toLowerCase())) {
      return true;
    }
  }

  return false;
};
